import { IoCloseSharp, IoLocationOutline } from "react-icons/io5";
import back from './assets/images/back.jpeg';
import logo from './assets/images/logo.jpg';
import axios from 'axios';
import { useEffect, useState } from "react";
import { MdAccessTime, MdOutlineDeliveryDining, MdPhoneInTalk } from "react-icons/md";
import { FaPhone, FaShippingFast, FaSpinner } from "react-icons/fa";

function App() {
  const [items, setItems] = useState(null);
  const [categories, setCategories] = useState(null);
  const [activeCategorie, setActiveCategorie] = useState(null);
  const [AllItems, setAllItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get("https://api.softpro.me/CustomersAPIs/702822392/index.php").then((res) => {
      setLoading(false);
      setCategories(res.data.categories);
      setActiveCategorie(res.data.categories[0]);
      setAllItems(res.data.items);
    });
  }, []);

  function filter(category) {
    setActiveCategorie(category);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    if (AllItems) {
      setItems(AllItems.filter(item => item.idSubC === activeCategorie.idsubc));
    }
  }, [activeCategorie, AllItems]);

  const toggleInfo = () => {
    setIsInfoVisible(!isInfoVisible);
  };

  return (
    loading ? (
      <div className="w-screen h-screen flex justify-center text-center">
        <FaSpinner className="animate-spin text-[#A17860] my-auto text-6xl" />
      </div>
    ) : (
      <div className="px-2 flex justify-center text-center relative">
        <div className="border border-[#A17860] relative gap-5 container max-w-[29rem] max-md:w-full max-md:max-w-full flex flex-col w-[29rem] bg-[#E1E1E1] h-full">

          <div className="flex rounded-b-2xl overflow-hidden flex-col">
            <div style={{ backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${back})` }} className="w-full h-48">
              {/* <img src="http://www.qr.algorexe.com/images/Businesses//59o4tSXTKE1fQMOYxEOL55jEIHrueHoZxTFOC9Lf.jpg"></img> */}
            </div>
        
            <div className="flex max-md:gap-1 gap-5 flex-row h-36 bg-white">
              <div className=" relative ">
              <div style={{ backgroundImage: `url(${logo})`, backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }} className="min-w-44 h-44 max-md:w-36 max-md:min-w-36 max-md:h-36 rounded-full max-md:-translate-y-12 -translate-y-16 flex justify-center text-center max-md:ml-2 ml-5 border-8 border-[#A17860] bg-[#231F1E]">
                {/* <h2 className="cedarville-cursive-regular underline leading-8 max-md:text-sm text-lg my-auto text-white">AL BABA RESTAURANT</h2> */}
              </div>
              <div
      style={{
       clipPath: 'polygon(92% 1%, 100% 100%, 0px 100%, 10% 0px)',
      }}
      className="max-md:ml-4 ml-5 w-fit flex flex-row gap-1 px-4 mt-2 shadow-md shadow-red-500 rounded-md text-sm bg-red-500 bg-opacity-30 text-red-600 justify-center max-md:-translate-y-12 -translate-y-16"
    >
      <MdOutlineDeliveryDining className="my-auto" />
      <p>Free Delivery</p>
    </div>
  
              </div>
              <div className="w-fit mt-2 flex gap-2 flex-col justify-start text-left">
                <h2 className="text-black text-xl font-bold">Manouch & CO</h2>
                <p className="text-slate-600 text-sm font-bold">Resto Caffe </p>
                <p className="text-slate-600 text-sm font-normal"><MdAccessTime className="inline-flex text-[#A17860] my-auto mx-1" /><strong className=" text-[#A17860]">Open Time:</strong> <span>(8 am - 3 pm)</span>  <span>(7 pm - 12 am)</span></p>
              </div>
            </div>
          </div>

          <div className="mx-2 max-w-full gap-3 flex flex-row overflow-x-auto relative">
            {categories && categories.map((category) => (
              <button onClick={() => filter(category)} className={`${activeCategorie.idsubc === category.idsubc ? "border-2 border-[#A17860]" : ""} px-1 py-1 rounded-lg min-w-28 bg-white`}>
                <h2>{category.description}</h2>
              </button>
            ))}
          </div>

          <div className="flex flex-col gap-2 mx-4">
            {items && items.map((item, index) => (
              <div key={index} className="shadow-[#A17860] shadow-sm flex py-3 px-2 gap-4 flex-row justify-start w-full bg-white rounded-lg h-32">
                {item.pic ? (
                  <div style={{ backgroundImage: `url(https://api.softpro.me/CustomersAPIs/702822392/images/${item.pic})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="rounded-lg w-44"></div>
                ) : (
                  <div style={{ backgroundImage: `url(https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg)`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="rounded-lg w-44"></div>
                )}
                <div className="py-3 flex flex-col text-left justify-between h-full w-full">
                  <h2 className="text-black text-sm font-semibold">{item.description}</h2>
                  <span className="text-sm font-bold">{Number(item.price1).toFixed(2)}$</span>
                </div>
              </div>
            ))}
          </div>
         <div className="fixed bottom-4 right-4 ">
       
          <button  onClick={toggleInfo} className="bg-[#A17860] text-white px-4 py-4 rounded-full shadow-lg">
           <FaPhone className=" text-2xl"/>
          </button>
          <div onClick={()=>setIsInfoVisible(false)} className={`fixed  inset-0 bg-black   bg-opacity-35 transition-all duration-500 ${isInfoVisible ? ' block opacity-100' : ' hidden opacity-0'}`}></div>
          <div className={`absolute bottom-16  text-nowrap  transition-all duration-500 ${isInfoVisible ? 'w-72 z-50 opacity-100 right-10  h-48' : 'w-0 right-0 -z-50 opacity-0 h-0'}`}>
          <div className=" relative overflow-hidden bg-white text-black  rounded-lg shadow-lg">
           <div className=" flex px-2 py-2 flex-col justify-start gap-10 text-start h-24 bg-[#A17860] text-white">
            <button onClick={()=>setIsInfoVisible(false)} className=" bg-white rounded-md text-[#A17860] w-fit"><IoCloseSharp/></button>
            <h2 className="text-lg mx-2  font-bold">Contact Information</h2>
            </div>
            <div className=" py-4 px-2 flex flex-col justify-start gap-3">
              <div className="flex flex-col justify-start text-start gap-1">
            <a type="tel" href="tel:+9619171270" className=" flex justify-start gap-2"><MdPhoneInTalk className=" my-auto text-2xl  text-[#A17860]"/>79 171 270</a>
          <p className=" text-xs text-gray-400">Phone Number</p>
            </div>
            <div  className="flex flex-col justify-start text-start gap-1">
            <p className=" flex justify-start gap-2"><IoLocationOutline className=" my-auto text-2xl  text-[#A17860]"/> <span className=" whitespace-pre-line"> Mejdlaya- Aakabe Road- Near Dahadah Station</span></p>
            <p className=" text-xs text-gray-400">Location</p>
            </div>
            </div>
            </div>
      
          </div>
          </div>
        </div>
      </div>
    )
  );
}

export default App;
